import { Button, Card, Col, Row } from "antd";

import { ContactUs } from "./contactUs";

import { quoteIcon, randomFacts } from "./randomFacts";

export const Hero = () => {
  // const websiteUrl = customUrlData.find(
  //   (data) => data.domain === window.location.hostname
  // );
  const selectRandomFact =
    randomFacts[Math.floor(Math.random() * randomFacts.length)].fact;

  return (
    <Card style={{ margin: "15px", maxHeight: "700px" }}>
      <Row style={{ maxWidth: "900px" }} gutter={[18, 18]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <h1>{window.location.hostname}</h1>
          <p>{window.location.href}</p>
          <p>
            <Button
              href="https://en.wikipedia.org/wiki/Stealth_mode"
              target="_blank"
              rel="noreferrer"
            >
              {"Stealth Mode"}
            </Button>
          </p>
        </Col>
        <Col xs={0} sm={0} md={12}>
          <div style={{ margin: "10px", width: "100%" }}>
            <Card
              title={"Random Facts 🙃"}
              style={{
                boxShadow: "2px 2px 2px 2px grey",
              }}
            >
              <div>
                {quoteIcon}
                <strong>
                  <p>{selectRandomFact}</p>
                </strong>
                <div style={{ float: "right" }}>{quoteIcon}</div>
              </div>
            </Card>
          </div>
        </Col>
        <ContactUs />
      </Row>
    </Card>
  );
};
