import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import ScrollToTop from "./utils/ScrollToTop";

function App() {

  return (
    <Router>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={<LandingPage/>}
        />
        <Route exact path="/*"  element={<LandingPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
