export const randomFacts = [
  {
    fact: "Bernd Eilts, a German artist, turns dried cow manure into wall clocks and small sculptures. He is now expanding his business to include cow dung wrist watches",
  },
  {
    fact: "During the era of Louis XIV, women used lemons to redden their lips",
  },
  {
    fact: "Polar bear livers contain so much Vitamin A that it can be fatal if eaten by a human",
  },
  {
    fact: "Hair and fingernails are made from the same substance, keratin",
  },
  {
    fact: "The youngest pope was 11 years old",
  },
  {
    fact: "Dandelion root can be roasted and ground as a coffee substitute",
  },
  {
    fact: "75-90% of primary physician visits are due to stress",
  },
  {
    fact: "Some dolphins can swim up to 40 kilometers an hour",
  },
  {
    fact: "A rhinoceros horn is made of compacted hair",
  },
  {
    fact: "In a year approximately 900 million trees are cut down to make the raw materials needed for American pulp mills and paper",
  },
  {
    fact: "When a women is pregnant, her senses are all heightened",
  },
  {
    fact: "Smiling releases endorphins in the body, which makes people feel better",
  },
  {
    fact: "The giant squid has the largest eyes in the world",
  },
  {
    fact: "A cubic yard of air weighs about 2 pounds at sea level.",
  },
  {
    fact: "The fat from sheep, which is called tallow can be used to make soap and candles",
  },
  {
    fact: "Brazil produces the most oranges in the world",
  },
  {
    fact: "38% of Americans eat breakfast everyday",
  },
  {
    fact: "Over 100 million birds die annually by crashing into glass windows in the United States",
  },
  {
    fact: "A chicken loses its feathers when it becomes stressed",
  },
  {
    fact: '"I am." is the second shortest complete sentence in the English language',
  },
  {
    fact: "The male praying mantis cannot copulate while its head is attached to its body. The female initiates sex by ripping the males head off",
  },
  {
    fact: "About 10% of the 100,000 thunderstorms that occur in the USA every year are classified as severe.",
  },
  {
    fact: "The tuatara lizard of New Zealand has three eyes, two in the center of its head and one on the top of its head",
  },
  {
    fact: "An average driver spends approximately 2 hours and 14 minutes kissing in their car in a lifetime",
  },
  {
    fact: "According to psychologists, the shoe and the foot are the most common sources of sexual fetishism in Western society",
  },
  {
    fact: "Every person has a unique tongue print",
  },
  {
    fact: "Archipelago is the word to describe a large group of islands that are located close together",
  },
  {
    fact: "The actor who played the T-1000 in Terminator 2 (Robert Patrick) and the lead singer of Filter are brothers",
  },
  {
    fact: "Dolphins can swim and sleep at the same time",
  },
  {
    fact: "Actor Sylvester Stallone once had a job as a lion cage cleaner",
  },
  {
    fact: "A honey bee has four wings",
  },
  {
    fact: 'The food that is digested in your stomach is called "chyme."',
  },
  {
    fact: "Chameleons can move their eyes independently. One eye can be looking forward and one eye backward at the same time",
  },
  {
    fact: "In America, the most common mental illness is Anxiety Disorders",
  },
  {
    fact: "Elvis Presley used to be a truck driver before he started singing",
  },
  {
    fact: 'Lighthouse keepers were nicknamed "wickies" because they tended the lamps wick',
  },
  {
    fact: "Every second, 8000 Coca-Cola Company products are consumed in the world",
  },
  {
    fact: "In 1990, the largest watermelon ever grown was 262 pounds, by Bill Carson of Tennesse, USA",
  },
  {
    fact: "Snake venom is ninety percent protein",
  },
  {
    fact: "In one minute, the heart of a giraffe can pump 160 gallons of blood",
  },
  {
    fact: "If a statue in the park of a person on a horse has both front legs inthe air, the person died in battle; if the horse has one front leg inthe air,the person died as a result of wounds received in battle; if the horsehasall four legs on the ground, the person died of natural causes.",
  },
  {
    fact: 'The smallest frog is the "Brazilian baby frog", which is smaller than a dime',
  },
  {
    fact: "Four billion pounds of watermelon were grown in the United States in 1999",
  },
  {
    fact: "A regulation baseball has exactly 108 stitches",
  },
  {
    fact: "The fastest moving land snake is the Black Mamba, which can move up to 7 miles per hour",
  },
  {
    fact: "The average person spends two weeks of their life kissing",
  },
  {
    fact: "The Eisenhower interstate system requires that one mile in every five must be straight. These straight sections are usable as airstrips in times of war or other emergencies",
  },
  {
    fact: "One female mouse can produce up to 100 babies a year",
  },
  {
    fact: "Percentage of Africa that is wilderness: 28%. Percentage of North America that is wilderness: 38%",
  },
  {
    fact: "The average Super Bowl party has 18 people",
  },
  {
    fact: "Bees can communicate with other bees by dancing. Their dance can alert other bees as to which direction and the distance nectar and pollen is located",
  },
  {
    fact: "A rocket-like device can be traced back to Ancient Greece when a flying steam-powered pigeon was built out of wood.",
  },
  {
    fact: "Next to bone marrow, hair is the fastest growing tissue in the human body",
  },
  {
    fact: "Cashew nuts contain oil in the shell that is very irritating to the skin",
  },
  {
    fact: "Uranus? winter and summer seasons last the equivalent of 21 Earth years",
  },
  {
    fact: "A ripe cranberry will bounce. Another name for a cranberry is bounceberry",
  },
  {
    fact: "Eating 11 pounds of apples will make you gain one pound of weight",
  },
  {
    fact: "In 1890, there was no sunshine for the whole month of December in Westminster in London.",
  },
  {
    fact: "Roughly 44% of junk mail is thrown away unopened",
  },
  {
    fact: "Before the fur trade had started in Canada, it was estimated that there were over 6 million beavers",
  },
];

export const quoteIcon = (
  <img
    alt="quotation marks"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABr0lEQVR4nO2YzStEURiHnwX5FgvLWfgcpSQLZWdrISnEQtlY+WtsZiFKNiI1yU7Z2FihRIpSPsrKx3wY1GR0605Np5eZOWcOC+9T7+7O87vvec/cOXdAURRFUZSyqQJai1RwjTMRYBtIArkiFVwTB6I/+HqAPSBbgi8L7AMDLjf/WEKQWU/hZ006gZSFLwV02DSwbRGWry3Bt+vg27FpoJRt810lBF/awZeyaSDnWL59RdEGDHQC5aJbyEC30L/bQskKBz7/dgNxh7CM4Nt08KVtGoiGBzObwEPB1w48WPoOsCQSHswSZQZOfONrA1aBu3BxnoSSfKN4YloI23DwLQi+GJ7oEiZzCTRZ+vqAV8N3CtThgRrg2Ah7c3h7agDOhSN0L56ICaMOxm/LmuCbwxOTQljwiLRlVvCt4IngvfbFCLsCmi193cIP5hlQjweqw+d7Ydg7MGjpqwVOhO9RP55YEka96OBbFnzzeGIc+DTCgn8tbJkRbn4dj1wYYddAi4PvxvBdAI145Kgg7AMYquCCZHzu+zzD4ardA1MV8I2ErltgrAI+RVEURVH4C74AkCdWtJ1JoPwAAAAASUVORK5CYII="
  />
);
