import { Hero } from "../components/landingPage/hero";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const LandingPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#f4f1ed",
        justifyContent: "center",
        display: "flex",
        height: "100vh",
      }}
    >
      <DynamicMetaTags ogTitle={window.location.host} ogImage={""} />
      <Hero />
    </div>
  );
};
